$(window).on("resize", function (){
	if ($(this).width() > 1025) {
		if (window.device == 'mobile') {
			location.reload()
		}
		window.device = 'desktop';
	}else{
		if (window.device == 'desktop') {
			location.reload()
		}
		window.device = 'mobile';
	}
}).trigger("resize")

var _scrollLast = 0
$(window).on("scroll", () => {
	var _scrollTop = $(window).scrollTop()
	var _allBottom = $("body").height() - $(window).height() - 100

	if (_scrollTop < 0 || _scrollTop > _allBottom) {
		return false;
	}

	if (_scrollTop > _scrollLast) {
		$("#topmenuWrap").addClass("is-hide")
	} else {
		$("#topmenuWrap").removeClass("is-hide")
	}

	_scrollLast = _scrollTop
})